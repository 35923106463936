import React from "react"
import dynamic from "next/dynamic"

import { useGlobalContext } from "../../context/layout-context"

const StayInTheLoop = dynamic(() => import("../forms/stay-loop"))
const FooterLink = dynamic(() => import("../buttons/footer-link"))
const Title = dynamic(() => import("../title/title"))
const IconType = dynamic(() => import("../icons/icons-group"))

function Footer({ footerButtons, socialLinks, footerLinks }) {
  const { globalState } = useGlobalContext()
  const { isMobile, isTablet } = globalState || {}

  return (
    <footer 
      className="bg-black py-9 lg:py-11 text-white relative"
      data-layer-region="footer"
    >
      <div className="mx-auto max-w-350px container lg:max-w-1280px">
        <div className="flex items-start justify-start flex-wrap -ml-32 lg:-ml-20 -mt-8">
          <div
            className="w-full lg:w-3/12 pl-32 lg:pl-20 text-left mt-8 lg:flex lg:flex-wrap lg:items-end mb-2"
            data-layer-component="footer in view"
          >
            <div className="w-full">
              <Title
                content="Stay in the loop"
                colorTitle="text-yellow-700"
                tag="h5"
                classList="mb-0 text-sm"
              />
              <StayInTheLoop classList="mt-4 mb-8" />
              <p className="text-xs mb-0">
                Sign me up to Nando's emails for access to exclusive events and
                Extra Hot contests!
              </p>
            </div>
          </div>
          {isMobile || isTablet ? (
            <div className="w-full lg:w-6/12 pl-32 lg:pl-20 text-center lg:text-right mt-8">
              <nav>
                <ul className="block -mt-2">
                  {footerLinks.items.map(item => (
                    <li
                      key={item.name}
                      className="mb-0"
                      data-layer-menu={item.name}
                    >
                      <FooterLink
                        href={`${item.slugUrl}/`}
                        className={item.class}
                        id={item.id}
                        removeUrl={item.removeUrl}
                        classList="transition-all duration-300 text-xs px-4 pt-4 pb-3 lg:pt-8 lg:pb-7 first:pl-0 last:pr-0 uppercase hover:text-pink-500 h-full flex items-center"
                      >
                        {item.name}
                      </FooterLink>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          ) : null}

          {isMobile || isTablet ? null : (
            <div className="w-full lg:w-6/12 pl-32 lg:pl-20 text-center lg:text-right mt-8 lg:flex lg:flex-wrap lg:items-start mb-3">
              <nav className="w-full">
                <div className="block -mt-2 lg:flex lg:justify-between lg:gap-4">
                  <div className="lg:w-1/2">
                    <ul>
                      {footerLinks.items.slice(0, Math.ceil(footerLinks.items.length / 2)).map((item) => (
                        <li key={item.name} className="mb-0" data-layer-menu={item.name}>
                          <FooterLink
                            href={`${item.slugUrl}/`}
                            className={item.class}
                            id={item.id}
                            removeUrl={item.removeUrl}
                            classList="transition-all duration-300 text-xs px-4 pt-4 pb-3 lg:pt-8 lg:pb-7 first:pl-0 last:pr-0 uppercase hover:text-pink-500 h-full flex items-center"
                          >
                            {item.name}
                          </FooterLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="lg:w-1/2">
                    <ul>
                      {footerLinks.items.slice(Math.ceil(footerLinks.items.length / 2)).map((item) => (
                        <li key={item.name} className="mb-0" data-layer-menu={item.name}>
                          <FooterLink
                            href={`${item.slugUrl}/`}
                            className={item.class}
                            id={item.id}
                            removeUrl={item.removeUrl}
                            classList="transition-all duration-300 text-xs px-4 pt-4 pb-3 lg:pt-8 lg:pb-7 first:pl-0 last:pr-0 uppercase hover:text-pink-500 h-full flex items-center"
                          >
                            {item.name}
                          </FooterLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          )}

          <div className="w-full lg:w-3/12 pl-32 lg:pl-20 text-center lg:text-center mt-8 mb-5">
            {footerButtons.items.length > 0 && (
              <div className="w-full mb-10">
                <Title
                  content="Get the app"
                  colorTitle="text-yellow-700"
                  tag="h5"
                  classList="mb-0 text-sm"
                />
                <div className="flex items-stretch lg:-mt-4 justify-around">
                  {footerButtons.items.map(button => (
                    <React.Fragment key={button.iconType}>
                      <div className="pl-0 lg:w-1/2">
                        <IconType
                          type={button.iconType}
                          classList="w-full h-10"
                          url={button.slugUrl}
                          trackingEvent="FooterNavigationClick"
                        />
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            )}

            <Title
              content="Connect with Us"
              colorTitle="text-yellow-700"
              tag="h5"
              classList="mb-0 text-sm"
            />
            <div className="flex items-stretch justify-center flex-wrap w-full -ml-2 lg:ml-0">
              {socialLinks.items.map(item => (
                <div className="pl-4" key={item.iconType}>
                  <IconType
                    type={item.iconType}
                    classList="text-yellow-700 w-5 h-5"
                    url={`${item.slugUrl}/`}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="w-full pl-32 lg:pl-20">
            <p className="text-sm text-white mb-0 text-center">© Nando’s {new Date().getFullYear()}</p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
